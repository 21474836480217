import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./components/assets/css/LiveChat.css";
import DHome from './components/pages/desktop/Home';
import DCasino from './components/pages/desktop/Casino';
import Promotion from './components/pages/desktop/Promotion';
import Account from './components/pages/desktop/Account';
import Registion from './components/pages/desktop/Registion';
import Home from './components/pages/mobile/Home';
import Login from './components/pages/mobile/Login';
import SignUp from './components/pages/mobile/SignUp';
import MAccount from './components/pages/mobile/MAccount';
import Profile from './components/pages/mobile/Profile';
import MDeposit from './components/pages/mobile/MDeposit';
import MWithdrawal from './components/pages/mobile/MWithdrawal';
import BettingRecord from './components/pages/mobile/BettingRecord';
import TransactionRecord from './components/pages/mobile/TransactionRecord';
import TurnoverRecord from './components/pages/mobile/TurnoverRecord';
import RefrrelProgram from './components/pages/mobile/RefrrelProgram';
import ResetPassword from './components/pages/mobile/ResetPassword';
import MobileInbox from './components/pages/mobile/MobileInbox';
import VIPConvert from './components/pages/mobile/VIPConvert';
import Games from './components/pages/mobile/Games';
import GameRun from './components/pages/mobile/GameRun';
import MobilePromotion from './components/pages/mobile/MobilePromotion';
import ChatHome from './components/Chat/ChatHome';
import ChatHistory from './components/Chat/ChatHistory';
import ChatBox from './components/Chat/ChatBox';
import EXHome from './components/exchanges/EXHome';
import MatchOdds from './components/exchanges/MatchOdds';
import ExAccount from './components/exchanges/ExAccount';
import InPlay from './components/exchanges/InPlay';
import PaymentTimer from './components/pages/mobile/common/PaymentTimer';
import ForgotPassword from './components/pages/mobile/ForgotPassword';
import StoreCode from './StoreCodeComponents';

// Utility function to check if the device is mobile
const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

function App() {
  const isDeviceMobile = isMobile();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token')); 
  const [lastReloadTime, setLastReloadTime] = useState(Date.now()); 
  const location = useLocation();
  const [showSplash, setShowSplash] = useState(true); 
  const [logo, setLogo] = useState([]);

  // Function to dynamically set the favicon
  const setFavicon = (url) => {
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = url;
      document.head.appendChild(newLink);
    } else {
      link.href = url;
    }
  };

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logo`);
        if (!response.ok) {
          throw new Error("Failed to fetch promotions");
        }
        const data = await response.json();
        setLogo(data[0].loader_logo);
        setFavicon(`${process.env.REACT_APP_API_URL}/storage/${data[0].favicon}`); 
      } catch (error) {
        console.error("Error fetching promotions:", error);
      }
    };

    fetchPromotions();
  }, []);
  
 


  useEffect(() => {
    const fetchGeneral = async () => {
      try {
        
  
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/general`);
        
      
  
        if (!response.ok) {
          throw new Error("Failed to fetch General");
        }
  
        // Log the raw response before converting to JSON
     
  
        const data = await response.json();
      
        if (data) {
          document.title = data[0].site_title;
          console.log(data[0].site_title);
         
        }
  
      } catch (error) {
        console.error("Error fetching General:", error);
      }
    };
  
    fetchGeneral();
  }, []);
  
  

  useEffect(() => {
    // Hide the splash screen after 3 seconds
    const splashTimeout = setTimeout(() => setShowSplash(false), 1000);
    return () => clearTimeout(splashTimeout);
  }, []);

  const canReload = () => {
    const currentTime = Date.now();
    const reloadInterval = 5000; // 5 seconds in milliseconds
    if (currentTime - lastReloadTime < reloadInterval) {
      return false;
    }
    setLastReloadTime(currentTime);
    return true;
  };

  const fetchBalance = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/balance`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('balance', data.balance);
        } else if (response.status === 401) {
          handleLogout();
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    } else {
      console.warn('No token found in local storage.');
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    window.location.href = '/';
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchBalance();
      const interval = setInterval(fetchBalance, 10000);
      return () => clearInterval(interval);
    }
  }, [isLoggedIn]);

  const PrivateRoute = ({ element }) => {
    return isLoggedIn ? element : <Navigate to="/login" />;
  };

  // Import styles based on the device type and current route
  useEffect(() => {
    if (isDeviceMobile) {
      import('./components/assets/css/Mobile.css');
      import('./components/assets/css/flipclock.css');
    } else {
      import('./components/assets/css/main-ui.css');
      import('./components/assets/css/palyer-ui.css');
    }
  }, [isDeviceMobile]);

  return (
    <div className={`App ${isDeviceMobile ? 'mobile' : 'desktop'}`}>
      {window.location.origin === 'https://www.jitbuzz.com' && (
              <Helmet>
                <meta
                  property="og:title"
                  content="Jeetbuzz is the most trusted betting exchange over Bangladesh and India"
                />
               
                <meta name="description" content="Bangladeshi's No #1 Cricket Betting App" />
                <title>9W || Jeetbuzz - Official Online Cricket Betting and Casino Bangladesh</title>
                <link
                  rel="apple-touch-icon"
                  href="https://1tapwin.click/storage/logos/ZwwR4eQzXOIB2JH5qS9RxqF6sgALyEWpDOSrn5s7.png"
                />
              </Helmet>
            )}

      {/* Splash Screen */}
      
      {showSplash ? (
        <div className="splash-screen">
          <img src={`${process.env.REACT_APP_API_URL}/${logo}`} alt="Loading..." />
        </div>
      ) : (
        <Routes>
          {isDeviceMobile ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/games" element={<Games />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/account" element={<PrivateRoute element={<MAccount />} />} />
              <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
              <Route path="/deposit" element={<PrivateRoute element={<MDeposit />} />} />
              <Route path="/withdrawal" element={<PrivateRoute element={<MWithdrawal />} />} />
              <Route path="/betting-record" element={<PrivateRoute element={<BettingRecord />} />} />
              <Route path="/transaction-record" element={<PrivateRoute element={<TransactionRecord />} />} />
              <Route path="/turnover-record" element={<PrivateRoute element={<TurnoverRecord />} />} />
              <Route path="/refrrel-program" element={<PrivateRoute element={<RefrrelProgram />} />} />
              <Route path="/reset-password" element={<PrivateRoute element={<ResetPassword />} />} />
              <Route path="/inbox" element={<PrivateRoute element={<MobileInbox />} />} />
              <Route path="/vip" element={<PrivateRoute element={<VIPConvert />} />} />
              <Route path="/gamerun" element={<PrivateRoute element={<GameRun />} />} />
              <Route path="/promotions" element={<PrivateRoute element={<MobilePromotion />} />} />
              <Route path="/chat" element={<PrivateRoute element={<ChatHome />} />} />
              <Route path="/chat-list" element={<PrivateRoute element={<ChatHistory />} />} />
              <Route path="/chat-box/:chatId" element={<PrivateRoute element={<ChatBox />} />} />
              <Route path="/odds-view" element={<PrivateRoute element={<MatchOdds />} />} />
              <Route path="/exchange" element={<PrivateRoute element={<EXHome />} />} />
              <Route path="/exchange-account" element={<PrivateRoute element={<ExAccount />} />} />
              <Route path="/exchange-inplay" element={<PrivateRoute element={<InPlay />} />} />
              <Route path="/payment-request" element={<PrivateRoute element={<PaymentTimer />} />} />
              <Route path="/referral_code" element={<StoreCode codeType="referral_code" />} />
              <Route path="/affiliate_code" element={<StoreCode codeType="affiliate_code" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<DHome />} />
              <Route path="/account" element={<Account />} />
              <Route path="/casino" element={<DCasino />} />
            </>
          )}
          <Route path="/promotions" element={<Promotion />} />
          <Route path="/register" element={<Registion />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
