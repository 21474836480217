import React, { useState, useEffect, useRef  } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const sections = [
    { title: "Sports", icon: "./icons/icon-sport.png", type: "SPORTS" },
    { title: "Casino", icon: "./icons/icon-casino.png", type: "LIVE" },
    { title: "Slot", icon: "./icons/icon-slot.png", type: "SLOT" },
    { title: "Table", icon: "./icons/icon-table.png", type: "TABLE" },
    { title: "Poker", icon: "./icons/icon-arcade.png", type: "BINGO" },
    { title: "Crash", icon: "./icons/icon-crash.png", type: "EGAME" },
    { title: "Fishing", icon: "./icons/icon-fish.png", type: "FH" },
    { title: "Lottery", icon: "./icons/icon-lottery.png", type: "LOTTO" },
    
];

const sportsOptions = [
    { name: "Exchange", icon: "icon-exchange.svg", link: "/exchange" },
    { name: "I-Sports", icon: "icon-sportbook.svg", code: "SABA-VIRTUAL-001", type: "VIRTUAL", platform: 'SABA'},
    { name: "E1Sports", icon: "icon-awcme1sport.svg" },
    { name: "Horsebook", icon: "icon-horsebook.svg", code: "HRB-LIVE-001", type: "LIVE", platform: 'HORSEBOOK'},
    { name: "Virtual", icon: "icon-virtual.svg" },
    { name: "Kabaddi", icon: "icon-kabaddi.svg" },
    { name: "Fantasy", icon: "icon-powerelevenv2.svg" },
    { name: "CMD Sports", icon: "icon-cmd.svg" },
];

const GameNavIcon = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [systems, setSystems] = useState([]);
    const [providers, setProviders] = useState([]);
    const [error, setError] = useState(null);
    const [contentPosition, setContentPosition] = useState("0px");
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
     const [isLoading, setIsLoading] = useState(false);
        const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token')); 

    useEffect(() => {
        const selectedSection = sections[activeIndex];
        if (selectedSection.type && selectedSection.type !== "SPORTS") {
            fetchSystems(selectedSection.type);
        } else {
            setSystems([]);
            setProviders([]);
        }
        const isMobileDevice = window.innerWidth <= 768;
        setIsMobile(isMobileDevice);
    }, [activeIndex]);
    const handleCasinoClick = (system, type, title) => {
        navigate('/games', { state: { system, type, title } });
    };

    const handleSectionClick = (index) => {
        setActiveIndex(index);
        setContentPosition("-100%");
        setTimeout(() => setContentPosition("0px"), 500);
    };

    const fetchSystems = async (type) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-system?type=${type}`);
            const data = await response.json();
            if (response.ok) {
                setSystems(data.systems || []);
                fetchProviders(data.systems);
            } else {
                setError(data.message || 'Failed to fetch systems');
            }
        } catch (error) {
            console.error('Error fetching systems:', error);
            setError('An unexpected error occurred while fetching systems');
        }
    };
    const handleGameClick = async (gameCode, gamePlatform, gameType) => {
        if(!isLoggedIn) {
          navigate('/login');
        }
        setIsLoading(true);
  
          try {
              const token = localStorage.getItem('token');
              const remote_id = localStorage.getItem('remote_id');
              if (!token) {
                  setError('User not logged in');
                  return;
              }
  
              const response = await fetch(`${process.env.REACT_APP_API_URL}/api/game-run`, {
                  method: 'POST',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`,
                  },
                  body: JSON.stringify({
                     userId : remote_id,
                     externalURL: 'https://worldbaji.live',
                     isMobileLogin: isMobile,
                     gameCode:gameCode,
                     gameType: gameType,
                     platform: gamePlatform
                  }),
              });
  
              const data = await response.json();
              if (response.ok) {
                  console.log(data.url);
                  navigate('/gamerun', { state: { iframeUrl: data.url } });
              } else {
                  setError(data.message || 'An unexpected error occurred');
              }
          } catch (error) {
              setError('An unexpected error occurred');
          }
          finally {
              setIsLoading(false);
          }
      };
  

    const fetchProviders = async (systems) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-provider?system=${JSON.stringify(systems)}`);
            const data = await response.json();
            if (response.ok) {
                setProviders(data.data || []);
            } else {
                setError(data.message || 'Failed to fetch providers');
            }
        } catch (error) {
            console.error('Error fetching providers:', error);
            setError('An unexpected error occurred while fetching providers');
        }
    };
    const navRef = useRef(null);
    const scrollPosRef = useRef(0);
    useEffect(() => {
        let isScrolling; // Variable to store the debounce timer
    
        // Initially remove the 'active' class when the user first visits
        const navElement = navRef.current;
        navElement.classList.remove('active');
    
        // Scroll event handler to check the scroll direction
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
    
            clearTimeout(isScrolling); // Clear any pending debounce timer
    
            // Debounce the scroll event handling to trigger after the user stops scrolling
            isScrolling = setTimeout(() => {
                if (currentScrollPos > scrollPosRef.current) {
                    // Scrolling down, add 'active' class
                    navElement.classList.add('active');
                } else {
                    // Scrolling up, remove 'active' class
                    navElement.classList.remove('active');
                }
    
                // Update the last scroll position
                scrollPosRef.current = currentScrollPos;
            }, 0); // Adjust the timeout duration as needed for smoother performance
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(isScrolling); // Ensure no timeouts are left running
        };
    }, []);
    if(isLoading) {
        return(
          <div className="splash-screen">
          <img src={'./splash/mobile.gif'} alt="Loading..." />
        </div>
        );
      }
    

    return (
        <div className="mcd-game-nav-icon-inner">
            <div ref={navRef} id="nav" className="nav nav-category nav-auto active">
                {sections.map((section, index) => (
                    <div
                        key={index}
                        className={`btn ${activeIndex === index ? 'selected' : ''}`}
                        onClick={() => handleSectionClick(index)}
                    >
                        <div className="icon">
                            <span
                                className="item-icon"
                                style={{
                                    backgroundImage: `url(${section.icon})`
                                }}
                            ></span>
                        </div>
                        <p>{section.title}</p>
                    </div>
                ))}
            </div>
            <div className="nav-wrap">
                <div id="nav-title" className="content-title">
                    <h2>
                        <span>{sections[activeIndex].title}</span>
                    </h2>
                </div>
                <div className="nav-content-wrap">
                    <div
                        className="nav-content-inner"
                        style={{
                            transform: `translateX(${contentPosition})`,
                            transition: "transform 0.5s ease"
                        }}
                    >
                        <div className="content-box">
                            <div className="layout-brand">
                                <div className="card1">
                                    {sections[activeIndex].type === "SPORTS" ? (
                                        <ul>
                                            {sportsOptions.map((sport, index) => (
                                                <li key={index}>
                                                {sport.link  ? (
                                                    <Link to={sport.link} 
                                                       >
                                                        <div
                                                            className="icon-sport"
                                                            style={{
                                                                maskImage: `url(https://img.j189eb.com/jb/h5/assets/images/icon-set/sports-icon/${sport.icon}?v=1729676340443)`
                                                            }}
                                                        ></div>
                                                        <p>{sport.name}</p>
                                                    </Link>
                                                ) : (
                                                    <div
                                                    onClick={sport.code && sport.platform && sport.type ? () => handleGameClick(sport.code, sport.platform, sport.type) : null}

                                                        style={{
                                                            cursor: sport.code ? 'pointer' : 'default'
                                                        }}
                                                    >
                                                        <div
                                                            className="icon-sport"
                                                            style={{
                                                                maskImage: `url(https://img.j189eb.com/jb/h5/assets/images/icon-set/sports-icon/${sport.icon}?v=1729676340443)`
                                                            }}
                                                        ></div>
                                                        <p>{sport.name}</p>
                                                    </div>
                                                )}
                                            </li>
                                            
                                            ))}
                                        </ul>
                                    ) : (
                                        <ul>
                                            {providers.length > 0 ? (
                                                providers.map((provider, index) => (
                                                    <li key={index}>
                                                        <a onClick={() => handleCasinoClick(provider.platform, sections[activeIndex].type, sections[activeIndex].title )} >
                                                            <img
                                                                src={`./icons/platform/${provider.platform}.png`}
                                                                alt={provider.platform}
                                                                className="ng-star-inserted"
                                                            />
                                                            <p>{provider.platform}</p>
                                                        </a>
                                                    </li>
                                                ))
                                            ) : (
                                                <p>No providers available</p>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameNavIcon;
