import React, { useState, useEffect } from "react";

const MobileCarouselBanner = () => {
    const [sliders, setSliders] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const fetchSliders = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sliders`);
                const data = await response.json();
                const activeMainSliders = data.filter(slider => slider.status === 'active' && slider.slider_type === 'Main');
                setSliders(activeMainSliders);
            } catch (error) {
                console.error('Error fetching sliders:', error);
            }
        };

        fetchSliders();
    }, []);

    useEffect(() => {
        if (sliders.length > 0) {
            const progressInterval = setInterval(() => {
                setProgress((prev) => (prev < 100 ? prev + 1 : 0));
            }, 30);

            const slideInterval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % sliders.length);
                setProgress(0); 
            }, 3000);

            return () => {
                clearInterval(progressInterval);
                clearInterval(slideInterval);
            };
        }
    }, [sliders.length]);

    const goToSlide = (index) => {
        setCurrentIndex(index);
        setProgress(0);
    };

    return (
            <div dir="ltr" className="banner">
                <div className="banner-v1">
                    <div className="carousel-wrap style-init siblings" data-auto="true" >
                        <div
                            className="cdk-drag item-drag"
                            style={{
                                transform: `translate3d(0px, 0px, 0px)`, // Ensure smooth looping
                              
                            }}
                        >
                            <div className="item-left">
                                <div className="item-wrap" style={{
                                transform: `translate3d(${(currentIndex % sliders.length) * -50 + 70}%, 0px, 0px)`, // Ensure smooth looping
                                transition: 'transform 0.5s ease-in-out' 
                            }} >
                                    {sliders.map((slider, index) => (
                                        <div
                                            key={slider.message_id}
                                            className="item"
                                            message-id={slider.message_id}
                                            style={{
                                                width: '70%',
                                            }}
                                            onClick={() => goToSlide(index)}
                                        >
                                            <div
                                                className="item-pic"
                                                style={{
                                                    backgroundImage: `url("${process.env.REACT_APP_API_URL}/storage/${slider.thubmnail}")`
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <ul className="dot-group style-bar">
                            {sliders.map((_, index) => (
                                <li
                                    key={index}
                                    onClick={() => goToSlide(index)}
                                    className={`ng-star-inserted ${index === currentIndex ? 'active' : ''}`}
                                >
                                    <span className="dot-progress" style={{ animationDuration: '3000ms' }} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
    );
};

export default MobileCarouselBanner;
