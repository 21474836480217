import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [errors, setErrors] = useState({});
    const [passwordShown, setPasswordShown] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state for submission status
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let tempErrors = {};
        const usernamePattern = /^[a-zA-Z0-9]+$/;

        if (!formData.username) tempErrors.username = "Username is required";
        else if (!usernamePattern.test(formData.username)) tempErrors.username = "Invalid username (only letters and numbers)";

        if (!formData.password) tempErrors.password = "Password is required";
        else if (formData.password.length < 8) tempErrors.password = "Password must be at least 8 characters";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setIsSubmitting(true); // Disable the button during submission
            await handleLogin(formData);
            setIsSubmitting(false); // Re-enable the button after submission
        } else {
            console.log("Form has errors. Please fix them and try again.");
        }
    };

    const [logo, setLogo] = useState([]);
    useEffect(() => {
        const fetchPromotions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logo`);
                if (!response.ok) {
                    throw new Error("Failed to fetch promotions");
                }
                const data = await response.json();
                setLogo(data[0].logo);
            } catch (error) {
                console.error("Error fetching promotions:", error);
            }
        };

        fetchPromotions();
    }, []);

    const handleLogin = async ({ username, password }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();

            if (response.ok) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('remote_id', data.remote_id);
                localStorage.setItem('username', data.username);
                localStorage.setItem('exchangeToken', data.exchange[0].token);

                window.location.href = '/'; // Redirect to a dashboard page
            } else {
                setErrors({ password: data.message || 'Invalid credentials' });
            }
        } catch (error) {
            console.error('Error during login:', error);
            setErrors({ password: 'Network error, please try again later.' });
        }
    };

    return (
        
            <div  className="main-router-wrapper ng-tns-c518043276-0 ng-trigger ng-trigger-routeFullPageAni">
               
                    <header  id="header" className="member ng-star-inserted">
                        <div>
                            <div  className="header-left-btn-group">
                                <Link to={'/'}>
                                    <div
                                        
                                        mcdprevious
                                        className="back-btn ng-star-inserted"
                                        style={{
                                            maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-arrow-type09.svg?v=1729676340443")',
                                        }}
                                    />
                                </Link>
                            </div>
                            <div  className="header-title ng-star-inserted">Login</div>
                        </div>
                    </header>
            
                <div  className="wrap ">
                    <div  className="content  login-biometric-content">
                        <div className="ng-star-inserted">
                            <div className="logo-box ng-star-inserted" style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}/storage/${logo}')` }} />
                            <form noValidate className="ng-untouched ng-pristine ng-invalid" onSubmit={handleSubmit}>
                                <div className="member-box radius">
                                    <div className="inputbox-wrapper">
                                        <div className="inputbox">
                                            <label htmlFor="userId">Username</label>
                                            <input
                                                type="text"
                                                className="input ng-untouched ng-pristine ng-invalid"
                                                name="username"
                                                placeholder="Username"
                                                value={formData.username}
                                                onChange={handleChange}
                                            />
                                            <input type="button" className="clear" />
                                        </div>
                                        {errors.username && <div className="member-error "><span> {errors.username} </span></div>}
                                    </div>
                                    <div className="inputbox-wrapper">
                                        <div className="inputbox password">
                                            <div
                                                className={`eyes ${passwordShown ? 'active' : ''}`}
                                                onClick={togglePasswordVisibility}
                                            />
                                            <label htmlFor="password">Password</label>
                                            <input
                                                type={passwordShown ? "text" : "password"}
                                                className="input ng-untouched ng-pristine ng-invalid"
                                                name="password"
                                                placeholder="Password"
                                                value={formData.password}
                                                onChange={handleChange}
                                            />
                                            <input type="button" className="clear" />
                                        </div>
                                        {errors.password && <div className="member-error"><span> {errors.password} </span></div>}
                                    </div>
                                </div>
                                {errors.password && <p className="error-message" style={{ color: 'red', marginTop: '10px' }}>{errors.password}</p>}
                                <div className="login-info-box">
                                    <div className="forgetpassword-buttn">
                                        <Link to={'/forgot-password'}>Forgot password?</Link></div>
                                </div>

                                <button
                                    style={{ background: isSubmitting ? '#ccc' : '' }} // Disable style
                                    className="button"
                                    type="submit"
                                    disabled={isSubmitting} 
                                >
                                    {isSubmitting ? 'Logging in...' : 'Login'} {/* Button text changes */}
                                </button>

                                <p className="signup">
                                    <span>Do not have an account? </span>
                                    <Link to="/signup">Sign up</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
       
    );
};

export default Login;
