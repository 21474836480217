import React, { useState, useEffect } from 'react';
import Logo from "../../common/mobile/Logo";
import Aside from "../../navigation/mobile/Aside";
import { Link } from "react-router-dom";

const MobileHeader = () => {
  const [sociallink, setSociallink] = useState([]);
  const [error, setError] = useState(null);
 
  const OpenTabBar = () => {
    const chatElement = document.querySelector('.cdk-overlay-container');
        chatElement.classList.remove('d-none');
        const aside = document.querySelector('.aside');
        aside.classList.add('active'); 
    
  };

  const fetchSocial = async () => {
          
          try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sociallink`);
              const data = await response.json();
              if (response.ok) {
                  setSociallink(data);
                 
              } else {
                  setError(data);
                  
              }
          } catch (error) {
              console.error('Error fetching social links:', error);
              setError('An unexpected error occurred while fetching social links');
          }
      };
      useEffect(() => {
          fetchSocial();
      }, []); 
  
      const handleChatClick = () => {
        if (window.Intercom) {
          window.Intercom('show');  // Show the Intercom Messenger
        }
      };
      const origin = window.location.origin.replace(/^https?:\/\/(www\.)?/, '').replace(/(\.com|\.live|\.net)$/i, '');
  return (
    <div className="mcd-header-inner">
      <header id="header" className="normal">
        <div className="header-left-btn-group">
          
          <div onClick={OpenTabBar} className="menu-btn">
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
        <div className="header-title"></div>
        <Logo/>
        <div name="customContentSponsor" className="custom-content-sponsor">
          <div className="hd-spon">
            
              <div className="carousel-frame-alpha" carousel-id="carousel-frame-alpha-1">
                <div className="carousel-frame-alpha__shell" style={{ overflow: "hidden" }}>
                  <div className="carousel-frame-alpha__track" style={{ transform: "translate(0px, 0px)" }}>
                    <div
                      data-carousel-unit-index="0"
                      className="carousel-frame-alpha__unit carousel-frame-alpha__unit--duplicated carousel-frame-alpha__unit--prev"
                      style={{ width: "calc(100% + 0px)" }}
                    >
                      <img
                        src="https://img.j189eb.com/jb/h5/assets/images/sponsor/delhi-bulls.png?v=1729676340443&source=mcdsrc"
                        alt="icon-sponsor"
                        className="hd-spon__icon"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
           
          </div>
        </div>
        <div className="header-right-btn-group">
         
          <Link className="service-btn" onClick={handleChatClick}>
          <span
              className="item-icon"
              style={{
                maskImage: "url('https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/header-service-icon.svg?v=1729676340443')",
              }}
            ></span>
            <p className="ng-star-inserted">Help</p>
          </Link>
          <a
          className="app-download ng-star-inserted"
          href={`./app/${origin}.apk`}>
        <span
          className="item-icon"
          style={{
            maskImage:
              'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/index-theme-icon/header-appdownload-icon.svg?v=1737451131402")',
          }}
        />
          <p  className="ng-star-inserted">App</p>
        </a>

          
          <div
            className="editor-btn"
            style={{ display: "none", maskImage: "url('https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-editor.svg?v=1729676340443')" }}
          ></div>
          <mcd-language className="ng-tns-c2605279879-3 ng-star-inserted"></mcd-language>
        </div>
      </header>
      <Aside/>
    </div>
  );
};

export default MobileHeader;
