import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const GameRun = () => {
  const location = useLocation();
  const { iframeUrl } = location.state || {};
  const iframeRef = useRef(null);  // Reference to the iframe

  useEffect(() => {
    // Attempt to make the iframe container full screen on load
    const enterFullScreen = () => {
      if (iframeRef.current) {
        if (iframeRef.current.requestFullscreen) {
          iframeRef.current.requestFullscreen();
        } else if (iframeRef.current.mozRequestFullScreen) { /* Firefox */
          iframeRef.current.mozRequestFullScreen();
        } else if (iframeRef.current.webkitRequestFullscreen) { /* Chrome, Safari, and Opera */
          iframeRef.current.webkitRequestFullscreen();
        } else if (iframeRef.current.msRequestFullscreen) { /* IE/Edge */
          iframeRef.current.msRequestFullscreen();
        }
      }
    };

    // Call full screen when the iframe loads
    if (iframeUrl) {
      setTimeout(enterFullScreen, 1000);  // Slight delay to ensure iframe loads before full screen
    }
  }, [iframeUrl]);

  return (
    <div className="game-lunch" style={{ width: '100%', height: '100vh', position: 'relative' }} ref={iframeRef}>
      {iframeUrl ? (
        <iframe
          title="Game"
          style={{ width: '100%', height: '100%', border: 'none' }}
          src={iframeUrl}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      ) : (
        <p>No game URL provided</p>
      )}
    </div>
  );
};

export default GameRun;
