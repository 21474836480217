import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import WithdrawNotice from "../../notice/mobile/WithdrawNotice";
import Toolbar from "../../navigation/mobile/Toolbar";
import UniversalBack from "../../common/mobile/UniversalBack";
import { Modal } from "react-bootstrap"; 

function MWithdrawal() {
  const [activeItem, setActiveItem] = useState(null); 
  const [activeAmount, setActiveAmount] = useState(""); 
  const [items, setItems] = useState([]);
  const [icon, setIcons] = useState(''); 
  const [accountNumber, setAccountNumber] = useState(""); 
  const [submitLoading, setSubmitLoading] = useState(false); 
  const [showModal, setShowModal] = useState(false); 
  const [modalMessage, setModalMessage] = useState(""); 
  const [onoff, setOnOff] = useState(''); 
  const [isLoggedIn] = useState(!!localStorage.getItem('token'));
  const [userData, setUserData] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false); // Track refresh status
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const remoteId = localStorage.getItem('remote_id');

  const amounts = ["2000", "3000", "5000", "10000", "15000", "20000", "25000", "1000", "500"];
  
      // Function to fetch user data (used on mount and for manual refresh)
      const fetchUserData = async () => {
          setIsRefreshing(true); // Add 'active' class to refresh icon
          const token = localStorage.getItem('token');
          const remoteId = localStorage.getItem('remote_id');
          if (token && remoteId) {
              try {
                  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/show?remote_id=${remoteId}`, {
                      method: 'GET',
                      headers: {
                          'Authorization': `Bearer ${token}`,
                      },
                  });
  
                  if (response.ok) {
                      const data = await response.json();
                      setUserData(data);
                  } else {
                      console.error('Failed to fetch user data:', response.status);
                  }
              } catch (error) {
                  console.error('Error fetching user data:', error);
              } finally {
                  setIsRefreshing(false); // Remove 'active' class after response
              }
          }
      };
  
      // Initial data fetch on mount
      useEffect(() => {
          if (isLoggedIn) {
              fetchUserData();
          }
      }, [isLoggedIn]);

  

  // Fetch payment methods from API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/payment-methods`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Origin': window.location.origin, 
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const uniqueItems = [];
        const seenNames = new Set();
  
        data.forEach((item) => {
          if (!seenNames.has(item.name)) {
            seenNames.add(item.name);
            uniqueItems.push(item);
          }
        });
  
        setItems(uniqueItems); 
      })
      .catch((error) => console.error("Error fetching payment methods:", error));
  }, []);
  


  const handleItemClick = (id, icon) => {
    setActiveItem(id); 
    setIcons(icon);
  };

  const handleAmountClick = (amount) => {
    setActiveAmount(amount); // Update selected withdrawal amount
  };
  const checkUserEligibility = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/userwithdrawcheck?remote_id=${remoteId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      const data = await response.json();
      console.log('API response data:', data); // Debugging
  
      if (!response.ok) {
        // Handle different response statuses
  
        if (response.status === 400) {
          // Specific handling for 403 status (turnover not complete)
          setModalMessage(data.error || 'Please complete your turnover and try again.');
          setShowModal(true); // Show modal with the turnover message
          return false;
        } else {
          // Handle other errors (e.g., 400, 500)
          setModalMessage(data.error || 'An error occurred.');
          setShowModal(true); // Show modal with generic error
          return false;
        }
      }
  
      if (data.success) {
        // User is eligible for withdrawal
        return true;
      } else {
        // Fallback for other cases
        setModalMessage('An unknown error occurred.');
        setShowModal(true);
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  
  
  


  const handleSubmit = async () => {
    
    if (!accountNumber) {
      setModalMessage("Please provide us with your receiver account number.");
      setShowModal(true);
      return;
    }
  
    if (!activeAmount) {
      setModalMessage("Amount is required.");
      setShowModal(true);
      return;
    }
  
    if (!activeItem) {
      setModalMessage("Please select your payment method.");
      setShowModal(true);
      return;
    }
  
    // Check user eligibility
    const eligible = await checkUserEligibility();
    if (!eligible) {
    
      return;
    }
  
    setSubmitLoading(true);
  
    const remoteId = localStorage.getItem("remote_id");
  
    const requestBody = {
      remote_id: remoteId,
      account_number: accountNumber,
      amount: activeAmount,
      payment_id: activeItem,
    };
  
    console.log(requestBody);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/submit-withdraw`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        setModalMessage(errorData.error);
        setShowModal(true);
        return;
      }
  
      const data = await response.json();
  
      if (data.success) {
        setModalMessage("Withdrawal successful!");
        setShowModal(true);
        navigate("/payment-request", {
          state: {
            amount: activeAmount,
            payment_icon: icon,
          },
        });
      } else {
        setModalMessage(data.message);
        setShowModal(true);
      }
    } catch (error) {
      setModalMessage("An error occurred. Please try again.");
      setShowModal(true);
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
      const fetchGeneral = async () => {
        try {
          
    
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/general`);
          
          if (!response.ok) {
            throw new Error("Failed to fetch General");
          }
    
         
          const data = await response.json();
        
          if (data) {
            setOnOff(data[0].withdrawal_status)
          }
    
        } catch (error) {
          console.error("Error fetching General:", error);
        }
      };
    
      fetchGeneral();
    }, []);

  return (
    <div className="main-router-wrapper">
      <header id="header" className="login player">
        <div className="header-left-btn-group">
          <UniversalBack />
        </div>
        <div className="header-title">Funds</div>
        <div
          className="logo"
          tabIndex={0}
          style={{ backgroundImage: 'url("https://img.j189eb.com/jb/h5/assets/images/logo.png")' }}
        />
      </header>

      <div className="wrap">
        <div className="content fixed-tab player-content">
          <div className="tab-btn-section tab-btn-wrap">
            <div className="tab-btn tab-btn-bar">
              <div className="line" style={{ width: "calc(50%)", transform: "translate(100%, 0px)" }} />
              <div className="btn">
                <Link className="text-white" to="/deposit">
                  <div className="text">Deposit</div>
                </Link>
              </div>
              <div className="btn active">
                <Link className="text-white" to="/withdrawal">
                  <div className="text">Withdrawal</div>
                </Link>
              </div>
            </div>
          </div>

          <div className="tab-content disable">
            <div className="inner-wrap">
              <div className="inner-box deposit-wallet">
              <div className="player-top player-withdraw ">
              <div className="main-wallet-info ">
                <div className="operating-wallet " />
                <div className="renew-main-wallet ">
                  <span className="">Main Wallet</span>
                  <div className="icon-refresh"  onClick={fetchUserData} style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/icon-set/icon-refresh-type01.svg?v=1737451131402")'}} />
                </div>
                <h4 className=""><i id="locale-util-dicrective-2" className="" style={{display: 'initial'}}>৳ {userData?.balance || 0}</i></h4>
              </div>
              <span className="item-bg " style={{maskImage: 'url("https://img.j189eb.com/jb/h5/assets/images/player/bg-header-bottom.svg?v=1737451131402")'}} />
            </div>
            {onoff ?
                <div className="player-deposit-wrap">
                  <div className="player-deposit-step1">
                    <WithdrawNotice />
                    {/* Payment Method */}
                    <div className="menu-box">
                      <div className="title">
                        <h2>Payment Method</h2>
                      </div>
                      <div className="select-group checkbox-style">
                        <ul className="col3">
                          {items.map(
                            (item) =>
                              item.status === "active" && (
                                <li
                                  key={item.id}
                                  onClick={() => handleItemClick(item.id, item.icon)}

                                  className="ng-star-inserted"
                                >
                                  <input
                                    type="radio"
                                    name="paymentMethod"
                                    id={`paymentMethod_${item.id}`}
                                  />
                                  <label htmlFor={`paymentMethod_${item.id}`}>
                                    <div className="bank ng-star-inserted">
                                      <img
                                        alt={item.name}
                                        src={`${process.env.REACT_APP_API_URL}/storage/${item.icon}`}
                                        loading="lazy"
                                      />
                                    </div>
                                    <span>{item.name}</span>
                                  </label>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>

                    {/* Amount Selection */}
                    <div className="menu-box active">
                      <div className="title">
                        <h2>
                          Amount <i>৳ 500.00 - ৳ 25,000.00</i>
                        </h2>
                      </div>
                      <div className="select-group style-add-amount">
                        <ul className="col4">
                          {amounts.map((amount) => (
                            <li
                              key={amount}
                              onClick={() => handleAmountClick(amount)}
                            >
                              <input
                                type="radio"
                                name="withdrawAmount"
                                id={`withdrawAmount_${amount}`}
                              />
                              <label htmlFor={`withdrawAmount_${amount}`}>
                                <span>{amount}</span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="input-group money">
                        <label htmlFor="amount">৳</label>
                        <div className="input-wrap">
                         <input
  type="text"
  name="amount"
  value={activeAmount}
  placeholder="0.00"
  onChange={(e) => setActiveAmount(e.target.value)}
/>
                        </div>
                      </div>

                      {/* Account Number */}
                      <div className="input-group money">
                        <label htmlFor="accountNumber">Account Number</label>
                        <div className="input-wrap">
                          <input
                            type="text"
                            name="accountNumber"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            placeholder="Enter your account number"
                          />
                        </div>
                      </div>

                      <div
                        className="tips-info note ng-star-inserted"
                        style={{}}
                      >
                        <h5 className="">
                          <i
                            className="tips-icon"
                            style={{
                              maskImage:
                                'url("/assets/images/icon-set/icon-tips-type02.svg")',
                            }}
                          />
                          <span className="">
                            Reminder : <br/> 1. Please double check the recipient's
                            account details before proceeding. <br/> 2. DO NOT share
                            your account with anyone to avoid losing funds or
                            money. <br/> 3. Please make sure your bank account holder
                            name and our registered name match to prevent
                            withdrawal rejection.
                          </span>
                        </h5>
                      </div>
                    </div>

                    {/* Submit Button */}
                    <div style={{textAlign: 'center'}} className="member-content">
                      
                        <button className="button" onClick={handleSubmit} disabled={submitLoading}>
                          {submitLoading ? "Submitting..." : "Submit"}
                        </button>
                      
                    </div>

                    {/* Modal for Messages */}
                    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Error</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>{modalMessage}</p>
                            </Modal.Body>
                        </Modal>
                  </div>
                </div>
                :  <div
                className="tips-info note ng-star-inserted"
                style={{}}
              >
                <h5 className="">
                  <i
                    className="tips-icon"
                    style={{
                      maskImage:
                        'url("/assets/images/icon-set/icon-tips-type02.svg")',
                    }}
                  />
                  <span className="">
                     Withdrawal System Upgrade & Maintenance Notice 📢
                    <br/>Dear Player,
                    <br/> Our withdrawal system is currently undergoing an upgrade and maintenance to ensure a smoother and more efficient experience. We are working diligently to enhance our services.
                    <br/> We expect the withdrawal system to be back online between 8:00 AM and 10:00 AM, after which you can use it as usual.
                    <br/>Thank you for your patience and cooperation.
                    <br/> — Team [WorldBaji]
                  </span>
                </h5>
              </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toolbar />
    </div>
  );
}

export default MWithdrawal;
