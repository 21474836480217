import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const FeatureGames = () => {
    const [featuredGames, setFeaturedGames] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
      const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token')); 
      const [isMobile, setIsMobile] = useState(false);
      const navigate = useNavigate();
      const [error, setError] = useState(null);

    // Fetch games data
    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sliders`);
                const data = await response.json();
                const featureGames = data.filter(game => game.status === 'active' && game.slider_type === 'Features');
                setFeaturedGames(featureGames); // Set the fetched games data
            } catch (error) {
                console.error('Error fetching games:', error);
            }
        };

        fetchGames();
    }, []);
    const handleGameClick = async (gameCode) => {
        if(!isLoggedIn) {
          navigate('/login');
        }
        setIsLoading(true);
  
          try {
              const token = localStorage.getItem('token');
              const remote_id = localStorage.getItem('remote_id');
              if (!token) {
                  setError('User not logged in');
                  return;
              }
              let parts = gameCode.split('-');

                  
                  let platform = parts[0];   
                  let gameType = parts[1];  
                 
            
              const response = await fetch(`${process.env.REACT_APP_API_URL}/api/game-run`, {
                  method: 'POST',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`,
                  },
                 
                  body: JSON.stringify({
                     userId : remote_id,
                     externalURL: 'http://www.worldbaji.live',
                     isMobileLogin: isMobile,
                     gameCode:gameCode,
                     gameType: gameType,
                     platform: platform
                     
                     
                  }),
                 
              });
  
              const data = await response.json();
              if (response.ok) {
                  console.log(data.url);
                  navigate('/gamerun', { state: { iframeUrl: data.url } });
              } else {
                  setError(data.message || 'An unexpected error occurred');
              }
          } catch (error) {
              setError('An unexpected error occurred');
          }
          finally {
              setIsLoading(false);
          }
      };
      if(isLoading) {
        return(
          <div className="splash-screen">
          <img src={'./splash/mobile.gif'} alt="Loading..." />
        </div>
        );
      }

    return (
        <mcd-feature-games>
            <div className="recommend feature-games">
                <div className="recommend-title">
                    <h2>Featured Games</h2>
                </div>
                <div className="recommend-bg games">
                    <div className="recommend-main games-main">
                        {featuredGames.length > 0 ? (
                            featuredGames.map((game, index) => (
                                <div onClick={() => handleGameClick(game.link)} className="games-box" key={index}>
                                    <div className="pic">
                                        <a target="_blank" rel="noopener noreferrer" >
                                            <img 
                                                alt={game.title} 
                                                src={`${process.env.REACT_APP_API_URL}/storage/${game.thubmnail}`} 
                                                loading="lazy" 
                                            />
                                        </a>
                                    </div>
                                    <div className="text">
                                        <h3>{game.title}</h3>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p></p>
                        )}
                    </div>
                </div>
            </div>
        </mcd-feature-games>
    );
};

export default FeatureGames;