import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Aside = () => {
    const [activeCategory, setActiveCategory] = useState("home");
    const [systems, setSystems] = useState([]);
    const [sociallink, setSociallink] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const CloseTabBar = () => {
        const chatElement = document.querySelector('.cdk-overlay-container');
            chatElement.classList.add('d-none');
            const aside = document.querySelector('.aside');
            aside.classList.remove('active'); 
      };
    const handleCategoryClick = (type) => {
        setActiveCategory(type);
    };
    const fetchSocial = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sociallink`);
            const data = await response.json();
            if (response.ok) {
                setSociallink(data);
               
            } else {
                setError(data);
                
            }
        } catch (error) {
            console.error('Error fetching social links:', error);
            setError('An unexpected error occurred while fetching social links');
        }
    };
    useEffect(() => {
        fetchSocial();
    }, []); 
    // Menu data
const menuData = [
    { title: "Home", icon: "icon-home.png", type: "home", link: "/" },
    { title: "Sports", icon: "icon-sport.png", type: "SPORTS" },
    { title: "Casino", icon: "icon-casino.png", type: "LIVE" },
    { title: "Slot", icon: "icon-slot.png", type: "SLOT" },
    { title: "Table", icon: "icon-table.png", type: "TABLE" },
    { title: "Poker", icon: "icon-arcade.png", type: "BINGO" },
    { title: "Crash", icon: "icon-crash.png", type: "EGAME" },
    { title: "Fishing", icon: "icon-fish.png", type: "FH" },
    { title: "Lottery", icon: "icon-lottery.png", type: "LOTTO" },
];

const promotionData = [
    { title: "Promotions", icon: "icon-promotion.png", link: "promotions" },
    { title: "Referral Program", icon: "icon-referral.png", link: "refrrel-program" },
    { title: "VIP", icon: "icon-vip.png", link: "/" },
];
const origin = window.location.origin
  .replace(/^https?:\/\/(www\.)?/, '');  // Removes 'http://', 'https://', and 'www.'


const supportData = [
    { title: "Affiliate", icon: "icon-affiliate.png", link: `https://affilaite.${origin}` },
    { title: "24/7 LiveChat", icon: "icon-talk.png", description: "Provides 24/7 Quality service", onclick: "handleChatClick", },
    { title: "Brand Ambassador", icon: "icon-ambassador.png", description: "Play with celebrity", link: "/" },
];

const providerData = [{ platform: "icon-all-provider.svg" }];

const sportsOptions = [
    { name: "Exchange", icon: "icon-exchange.svg", link: "/exchange" },
    { name: "I-Sports", icon: "icon-sportbook.svg" },
    { name: "E1Sports", icon: "icon-awcme1sport.svg" },
    { name: "Horsebook", icon: "icon-horsebook.svg" },
    { name: "Virtual", icon: "icon-virtual.svg" },
    { name: "Kabaddi", icon: "icon-kabaddi.svg" },
    { name: "Fantasy", icon: "icon-powerelevenv2.svg" },
    { name: "SBO Sports", icon: "icon-sbov2.svg" },
];


const [providers, setProviders] = useState(providerData);

    

    useEffect(() => {
        const selectedMenu = menuData.find(item => item.type === activeCategory);
        if (selectedMenu && activeCategory !== "home") {
            fetchSystems(selectedMenu.type);
        } else {
            setSystems([]);
            setProviders(providerData);
        }
    }, [activeCategory]);
    const handleCasinoClick = (system, type) => {
        const selectedMenu = menuData.find(item => item.type === type);
        if (selectedMenu) {
            navigate('/games', { state: { system, type: selectedMenu.type, title: selectedMenu.title } });
        } else {
            console.error('Selected menu item not found');
        }
    };
    

    const fetchSystems = async (type) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-system?type=${type}`);
            const data = await response.json();
            if (response.ok) {
                setSystems(data.systems || []);
                fetchProviders(data.systems);
            } else {
                setError(data.message || 'Failed to fetch systems');
            }
        } catch (error) {
            console.error('Error fetching systems:', error);
            setError('An unexpected error occurred while fetching systems');
        }
    };

    const fetchProviders = async (systems) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/get-provider?system=${JSON.stringify(systems)}`);
            const data = await response.json();
            if (response.ok) {
                setProviders([{ platform: "icon-all-provider.svg" }, ...(data.data || [])]);
                console.log('Providers fetched:', data.data);
            } else {
                setError(data.message || 'Failed to fetch providers');
            }
        } catch (error) {
            console.error('Error fetching providers:', error);
            setError('An unexpected error occurred while fetching providers');
        }
    };
    const handleChatClick = () => {
        if (window.Intercom) {
          window.Intercom('show');  
        }
      };

    return (
        <div className="cdk-overlay-container d-none">
            <div className="cdk-overlay-backdrop dialog-backdrop cdk-overlay-backdrop-showing" />
            <div className="cdk-global-overlay-wrapper" style={{ justifyContent: "center", alignItems: "center" }}>
                <div className="cdk-overlay-pane dialog-panel">
                    <div className="popup">
                        <div className="popup__header"></div>
                        <div className="popup__content">
                            <div className="menu aside">
                                <div className="menu-first">
                                    <ul className="home">
                                        {menuData.map((item, index) => (
                                            <li key={index} className={activeCategory === item.type ? "active" : ""}>
                                                <span className="item-icon" style={{ backgroundImage: `url(./icons/${item.icon})` }} />
                                                <a onClick={() => handleCategoryClick(item.type)} href={item.link || "#"}>
                                                    {item.title}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>

                                    <ul className="promotion-block">
                                        {promotionData.map((item, index) => (
                                            <li key={index}>
                                                <span className="item-icon" style={{ backgroundImage: `url(./icons/${item.icon})` }} />
                                                
                                                <Link to={item.link} >{item.title}</Link>
                                            </li>
                                        ))}
                                    </ul>

                                    <div className="support-block">
                                    {supportData.map((item, index) => (
                                                <Link 
                                                    key={index} 
                                                    to={item.link || "#"} 
                                                    onClick={item.onclick ? () => handleChatClick() : null} 
                                                >
                                                    <div className="service">
                                                        <span className="item-icon" style={{ backgroundImage: `url(./icons/${item.icon})` }} />
                                                        <p className='ng-star-inserted'>{item.title}</p>
                                                    </div>
                                                </Link>
                                            ))}
                                    </div>
                                </div>

                                {activeCategory !== "home" && (
                                    <div className="menu-second">
                                        {activeCategory === "SPORTS" && (
                                            <ul className="menu-second-ul active">
                                                {sportsOptions.map((sport, index) => (
                                                    <li key={index}>
                                                        <Link to={sport.link}>
                                                        
                                                            <div className="icon-sport" style={{ maskImage: `url(./icons/${sport.icon})` }} />
                                                            <p>{sport.name}</p>
                                                       
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}

                                        {!["home", "SPORTS"].includes(activeCategory) && (
                                            <ul className="menu-second-ul active">
                                                {providers.map((provider, index) => (
                                                    <li key={index}>
                                                        <a onClick={() => handleCasinoClick(provider.platform, activeCategory )} href="#">
                                                            {provider.platform.endsWith('.svg') ? (
                                                                <img className="icon-provider" src={`./icons/${provider.platform}`} alt={provider.name} />
                                                            ) : (
                                                                <img className="icon-provider" src={`./icons/platform/${provider.platform}.png`} alt={provider.name} />
                                                            )}
                                                            <p>{provider.platform.replace('icon-', '').replace('-', ' ')}</p>
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}

                                    </div>
                                )}
                            </div>
                            <div onClick={CloseTabBar} className="menu-mask" style={{ display: 'block' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Aside;
