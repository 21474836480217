import React, { useState, useEffect } from 'react';

const ScrollBanner = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token')); 
  const [sliders, setSliders] = useState([]);
  const exchangeToken = localStorage.getItem('exchangeToken');

  // Fetch sliders data
  useEffect(() => {
    const fetchSliders = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/sliders`);
        const data = await response.json();
        const activeMainSliders = data.filter(slider => slider.status === 'active' && slider.slider_type === 'Favourite');
        setSliders(activeMainSliders); // Set the fetched sliders
      } catch (error) {
        console.error('Error fetching sliders:', error);
      }
    };

    fetchSliders();
  }, []);

  // Handle navigation using window.location.href
  const handleNavigation = (link) => {
    if (isLoggedIn) {
      // If the user is logged in, navigate to the provided link with the exchangeToken
      if (link) {
        window.location.href = `https://tech.betskyexch.com/m/#/token/${exchangeToken}`;
      }
    } else {
      // If the user is not logged in, redirect to the login page
      window.location.href = '/login';
    }
  };

  return (
    <div className="recommend scroll-banner">
      <div className="recommend-title">
        <h2>Favourites</h2>
      </div>
      <div className="recommend-bg">
        <div className="recommend-main">
          {sliders.length > 0 ? (
            sliders.map((slider, index) => (
              <div className="recommend-card" key={index} onClick={() => handleNavigation(slider.link)}>
               <a>
               <img
                  alt={`slider_image_${index}`}
                  src={`${process.env.REACT_APP_API_URL}/storage/${slider.thubmnail}`}
                  loading="lazy"
                  style={{ cursor: 'pointer' }} // Change the cursor to pointer for better UX
                />
               </a>
              </div>
            ))
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScrollBanner;
